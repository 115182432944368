.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-color: #212121; */
  /* color: whitesmoke; */
  flex-direction: column;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.main-header{
  font-family: 'Montserrat', sans-serif;
}

.create-form{
  max-width: 350px !important;
  width: 100%;
}
.create-form .col-form{
  margin: 0 0 15px 0 ;
  display: block;
}
/* .btns{
  background-color: #005995;
  color: #fff;
  margin: 0;
  padding: 5px 7px;
  border: 0;
  border-radius: 4px;
  width: 100%;
} */

/* .create-form .label{
  
  font-family: 'Montserrat', sans-serif;
  font-size: 12px !important;
  display: block;
  margin-bottom: 5px;
} */
.mainwrap{
  padding-left: 1rem;
  padding-right: 1rem;
}
.form-control{
  margin: 0;
  padding: 5px 7px;
  background-color: #fff;
  border: 1px solid #ccc;
  display: block;
  border-radius: 4px;
  width: 100%;
}
.col-form p {
  color: red;
}
/* .sideBar{  min-height: 100vh;} */
.LogoCnt{
  /* justify-content: space-between; */
  display: flex;
  align-items: center;
  padding-top: 10px;
 
  /* padding-left: 15px; */
}
.ui.vertical.menu,
.navigation{
  background: linear-gradient(180deg, #97D3FC 0%, #B9E5E5 100%) !important;
  border-radius: 10px !important;
}
.ui.grid>.row{padding-bottom: 0;}
.sidebarnew{
  box-shadow:none !important;
  border:0 !important ;
}
.segment.pushable{
  height: calc(100vh - 120px)
}
.ui.thin.left.sidebar, .ui.thin.right.sidebar{
  width:250px !important;
}

.ui.visible.thin.left.sidebar ~ .fixed, .ui.visible.thin.left.sidebar ~ .pusher{
  transform: translate3d(250px,0,0) !important;
}
.ui.labeled.icon.menu .item{
  flex-direction: row !important;
}
.ui.icon.menu .item,
.ui.labeled.icon.menu{
  text-align:left !important
}
.ui.labeled.icon.menu .item > .icon:not(.dropdown){display:inline-block !important; padding-right:40px !important}

/* .ui.link.menu .item:hover, .ui.menu .dropdown.item:hover, .ui.menu .link.item:hover, .ui.menu a.item:hover{
  background:#005995 !important;
  color:#fff !important;
} */
h1.ui.header{
  font-size: 4rem !important;
}

.ui.container {
  padding-left: 15px !important;
padding-right: 15px !important;
}
.mt0{
  margin-top: 0px !important;
}
.mt20{
  margin-top: 20px !important;
}
.pt20{
  padding-top: 20px !important;
}
.pt40{
  padding-top: 40px !important;
}
.pl15{
  padding-left: 15px !important;
}
.pr20{
  padding-right: 20px !important;
}
.pb0{padding-bottom: 0 !important;}
.ptb0{padding-bottom: 0 !important; padding-top: 0 !important;}
.vmid{
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
}
.ui.text.menu .active.item{
  color: rgb(5, 110, 180);
  font-weight: bold;

}
.ui.header,
.ui.text.menu .item{
  color: rgb(5, 110, 180);
}
/* .ui.text.menu .item:hover{
  text-decoration: underline;
} */
.backbtn{font-size: 1.5rem;}

p.MuiTablePagination-selectLabel{margin: 0;}
.cstChip{
  min-width: 32px;
  min-height: 32px;
  border-radius: 40px;
  padding: 0;
  font-size: 25px !important;
  display: inline-flex;
  background-color: transparent !important;
}
.cstChip .MuiChip-icon{
  margin: 0 !important;
}
.cstChip .MuiChip-label{
  display: none ;
  
  /* background-color: red !important; */
}
.code{
  color: rgb(5, 110, 180);
  float: right;
  font-weight: 700;
  font-size: 16px;
}
.edition{
  font-weight: bold;
}
.year{float: right; font-weight: bold;}

.customInput .MuiTextField-root{
  border: 1px solid rgba(34,36,38,.15);
  background-color: #fff;
  border-radius: 3px;
}
.MuiDataGrid-toolbarContainer{
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  /* background-color: #819b8a14; */

}
.customInput .MuiFormControl-root{padding-bottom: 0;}
.customInput .MuiInput-underline::after,.customInput .MuiInput-underline::before{display: none;}

/* .customInput .css-v4u5dn-MuiInputBase-root-MuiInput-root::before{
  contain: ""; position: absolute; width: 30px; height: 32px;
  display: block; background-color: #e0e1e2;
} */
.customInput .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter .MuiSvgIcon-root{background-color: #e0e1e2; min-height: 32px; order: 3; min-width: 32px; padding: 6px;}
.customInput .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter .MuiIconButton-root .MuiSvgIcon-root{background-color: #fff; min-height: 32px; min-width: 32px; padding: 6px;}
/* .customInput .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter .MuiInputBase-formControl > .MuiSvgIcon-root{
  background-color: #e0e1e2;
  position: absolute; left: 6px;
} */
.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter input{margin-left: 15px !important;}
.customInput .MuiButtonBase-root{padding:0; padding-bottom: 0;}
.headername{
  padding-right: 25px !important;
}
.closeicon{
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  padding-left: 5px;
  color: red;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
cursor: pointer;
}
.header.sm{
  font-size: 14px !important;
}
.ui.cards>.card, .ui.card, .ui.cards>.card{

}
.ui.cards>.green.card, .ui.green.card, .ui.green.cards>.card{
  border: 1px solid rgb(8, 148, 8) !important;
  box-shadow: none !important;
}
.ui.cards>.red.card, .ui.red.card, .ui.red.cards>.card{
  border: 1px solid red !important;
  box-shadow: none !important;
}
.ui.cards>.red.card[disabled], .ui.red.card[disabled], .ui.red.cards>.card[disabled]{
  opacity: .6;
  pointer-events: none;
}
.MuiToolbar-root p.MuiTablePagination-displayedRows{padding-bottom: 0; margin-bottom: 0;}
.custom-autocomplete{min-width: 200px;}
.custom-autocomplete input  {border: 0 !important;}
.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url],
.ui.form select{border-color: #c4c4c4;}
.MuiDataGrid-columnHeaders{background-color: #819b8a14;}
.profile-pic{border-radius: 50%; max-width: 200px; max-height: 200px; border:3px solid #fff}
.profileBG{
  align-items: center;
  display: inline-flex !important;
  justify-content: center;
  flex-flow: column;
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));
}
.name{
  color: #fff !important;
  margin-bottom: 0 !important;
  margin-top: 10px !important;
}
.bdr{
  border:1px solid #ccc;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.m0{margin: 0 !important;}
.navbar .ui.menu{margin-bottom: 0;}
.uploadItem{display: flex;}
.uploadItem label{font-weight: bold}
.f1{flex: 1;}
.mt5{margin-top: 5px !important;}
.upload-file{padding: 5px !important;}